/**
 * @param {string | undefined} envVar
 * @param {string | undefined} defaultValue
 */
const parseConfigUrl = (envVar, defaultValue) => {
  const url = envVar || defaultValue || window.location.origin;
  if (/^https?:\/\//.test(url)) {
    return url;
  }
  if (url[0] === '/') {
    return `${window.location.origin}${url}`;
  }
  return `${window.location.origin}${window.location.pathname}/${url}`;
};

class ConfigStore {
  /** @type {Set<import('./store').OnStoreChange>} */
  #listeners = new Set();

  #notifyListeners() {
    this.#listeners.forEach((listener) => listener());
  }

  /**
   * @param {import('./store').OnStoreChange} listener
   * @returns {import('./store').UnsubscribeFn}
   */
  subscribe(listener) {
    this.#listeners.add(listener);
    return () => {
      this.#listeners.delete(listener);
    };
  }

  config = /** @type {const} */ ({
    // The URL for obtaining authentication tokens.
    cloud_storage_token_url: parseConfigUrl(
      process.env.REACT_APP_TOKEN_URL,
      `https://${window.location.hostname}/api/v1/storage/token`,
    ),
    // The URL for obtaining authorization codes.
    cloud_storage_code_url: parseConfigUrl(
      process.env.REACT_APP_CODE_URL,
      `https://${window.location.hostname}/api/v1/storage/code`,
    ),
    // The client ID for Google Drive authentication.
    gdrive_clientid                 : process.env.REACT_APP_GDRIVE_CLIENTID || '',
    // The client ID for OneDrive authentication.
    msgraph_clientid                : process.env.REACT_APP_GRAPH_CLIENTID || '',
    feature_flag_storage_explorer_v2: false,
    feature_flag_cs_store_v2        : false,
    feature_flag_msso_root          : false,
    feature_flag_user_collaboration : false,
  });

  /**
   * Updates the configuration with new values if provided.
   * @param {Partial<ConfigStore['config']>} newConfig
   */
  updateConfig(newConfig) {
    let didUpdateAnyValues = false;
    if (newConfig?.cloud_storage_token_url) {
      this.config.cloud_storage_token_url = parseConfigUrl(
        newConfig.cloud_storage_token_url,
      );
      didUpdateAnyValues = true;
    }
    if (newConfig?.cloud_storage_code_url) {
      this.config.cloud_storage_code_url = parseConfigUrl(
        newConfig.cloud_storage_code_url,
      );
      didUpdateAnyValues = true;
    }
    if (newConfig?.gdrive_clientid) {
      this.config.gdrive_clientid = newConfig.gdrive_clientid;
      didUpdateAnyValues = true;
    }
    if (newConfig?.msgraph_clientid) {
      this.config.msgraph_clientid = newConfig.msgraph_clientid;
      didUpdateAnyValues = true;
    }
    if (!newConfig?.feature_flag_msso_root) {
      this.config.feature_flag_storage_explorer_v2 = !newConfig?.feature_flag_msso_root;
      didUpdateAnyValues = true;
    }
    if (newConfig?.feature_flag_cs_store_v2) {
      this.config.feature_flag_cs_store_v2 = newConfig.feature_flag_cs_store_v2;
      didUpdateAnyValues = true;
    }
    if (newConfig?.feature_flag_user_collaboration) {
      this.config.feature_flag_user_collaboration = newConfig.feature_flag_user_collaboration;
      didUpdateAnyValues = true;
    }
    if (didUpdateAnyValues) {
      this.#notifyListeners();
    }
  }
}

export const configStore = new ConfigStore();
export default configStore;
