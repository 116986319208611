import type { OpenOptions, OpenResult } from './types';
import type { DataItem } from '../../clients/data/types';
import type { I18nLocale } from '../../clients/i18n/types';
import type { ModuleConfigKey, Plane } from '../../types';
import type ChannelService from '../services/channel';
import type DataService from '../services/data';
import type {
  CreateOptions,
  CreateResult,
  OpenOptions as DeprecatedOpenOptions,
  OpenResult as OldOpenResult,
} from '../services/plane/types';
import type { UserSettings, UserSubscription } from '../store/reducers/user/types';
import PlatformCore from '..';
import PlaneChannel from '../../clients/channel/plane-channel';
import moduleClient from '../../clients/module';
import config from '../../config';
import MODULES from '../../modules';
import platformStore from '../store';

/**
 * PlatformModuleBase is the base class that is used by all other platform module
 * classes to extend off of to provide consistent and shared functionality for
 * the different variations of platform modules.
 */
class PlatformModuleBase {
  /**
   * Get user settings.
   */
  get settings(): UserSettings {
    return PlatformCore.selectors.settings(PlatformCore.getState());
  }

  /**
   * Get user subscriptions.
   */
  get subscriptions(): UserSubscription[] {
    return PlatformCore.selectors.subscriptions(PlatformCore.getState());
  }

  /**
   * Get user locale.
   */
  get locale(): I18nLocale {
    return PlatformCore.selectors.locale(PlatformCore.getState());
  }

  /**
   * Get the data service.
   */
  get data(): DataService {
    return PlatformCore.Data;
  }

  /**
   * Get the channel service.
   */
  get channel(): ChannelService {
    return PlatformCore.Channel;
  }

  /**
   * Opens an channel to another plane by ID
   * @deprecated Use `channel` instead.
   * @param planeId - The ID of the plane to open a channel with
   * @param channel - The PlaneChannel to pass
   * @returns The opened analysis plane.
   */
  openChannel(planeId: string, channel: PlaneChannel): boolean {
    const plane = platformStore.selectors.plane(platformStore.getState(), planeId);
    if (plane) {
      moduleClient.Events.openChannel(plane, channel);
      return true;
    }
    return false;
  }

  /**
   * Creates a plane channel
   * @deprecated Use `channel` instead.
   * @param planeId - The ID of the plane creating the channel
   * @param peerPlaneId - The ID of the channel's intended receiver
   * @returns The new plane channel
   */
  createChannel(planeId: string, peerPlaneId: string) {
    return new PlaneChannel(planeId, peerPlaneId);
  }

  /**
   * Uses a selector for claims.
   */
  useClaims(): unknown {
    return PlatformCore.useSelector(PlatformCore.selectors.claims);
  }

  /**
   * Hook to get the user's subscriptions.
   */
  useSubscriptions(): UserSubscription[] {
    return PlatformCore.useSelector(PlatformCore.selectors.subscriptions);
  }

  /**
   * Uses a selector to get the user's locale settings.
   * @returns The locale settings.
   */
  useLocale(): I18nLocale | undefined {
    return PlatformCore.useSelector(PlatformCore.selectors.locale);
  }

  /**
   * Invokes another module's public methods registered during the module's initialization.
   * @param moduleKey - The key of the module to invoke the method on.
   * @param methodName - The name of the method to invoke.
   * @param args - The arguments to pass to the method.
   * @returns The result of the invoked method.
   */
  async invokeModuleMethod(moduleKey: ModuleConfigKey, methodName: string, ...args: unknown[]): Promise<unknown> {
    // TODO: Remove this once we update the Dashboard remote module to spread args instead of passing an array.
    const argsArray = Array.isArray(args[0]) ? args[0] : args || [];
    return await PlatformCore.Modules.invokeModuleMethod(moduleKey, methodName, ...argsArray);
  }

  /**
   * Opens a plane with the given options.
   * @param options - The options to open the plane with.
   * @returns The opened plane.
   */
  async open(options: OpenOptions): Promise<OpenResult> {
    if (config.feature_flag_open_api) {
      const { planeId } = await PlatformCore.Plane.open({
        name            : options.name,
        moduleKey       : options.moduleKey,
        data            : options.data,
        planeId         : options.planeId,
        useDefaultModule: options.useDefaultModule,
        skipDataPreview : options.skipDataPreview,
        state           : options.state,
        extra           : options.extra,
      });

      return { planeId };
    }

    // appease the parser, eventually we won't use invokeByModuleKey
    const data = options.data as DataItem;
    const planeId = await PlatformCore.Plane.invokeByModuleKey(options.moduleKey, { ...options, data });
    return { planeId: planeId || '' };
  }

  /**
   * Creates a new MSSO analysis plane with the given options.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createAnalysis(options: CreateOptions = {}): CreateResult {
    if (config.feature_flag_open_api) {
      const passthrough = options.state?.passthroughItem as { projectId: string; name: string };
      const data = passthrough
        ? PlatformCore.Data.createFromPassthrough(passthrough.projectId, passthrough.name)
        : undefined;
      const { planeId } = await PlatformCore.Plane.open({
        moduleKey      : MODULES.MSSO.key,
        data,
        skipDataPreview: true,
      });
      return planeId;
    }

    return await PlatformCore.Plane.createAnalysis(options);
  }

  /**
   * Creates a new Dashboard plane with the given options.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createDashboard(options: CreateOptions = {}): CreateResult {
    if (config.feature_flag_open_api) {
      const { planeId } = await PlatformCore.Plane.open({
        moduleKey: MODULES.DASHBOARD.key,
        data     : options.data,
      });
      return planeId;
    }

    return await PlatformCore.Plane.createDashboard(options);
  }

  /**
   * Opens an analysis plane with the given options.
   * @param options - The options to open the analysis with.
   * @returns The opened analysis plane.
   */
  async openAnalysis(options: DeprecatedOpenOptions): OldOpenResult {
    if (config.feature_flag_open_api) {
      const { planeId } = await PlatformCore.Plane.open({
        moduleKey      : MODULES.MSSO.key,
        data           : options.data,
        planeId        : options.planeId,
        skipDataPreview: options.skipDataPreview,
      });
      return planeId;
    }

    return await PlatformCore.Plane.openAnalysis(options);
  }

  /**
   * Opens the given plane by id
   */
  toPlane(id: string) {
    PlatformCore.Routing.toPlane(id);
  }

  /**
   * Gets the associated plane by value
   * @returns The plane.
   */
  getPlane(planeId: string): Plane | undefined {
    return PlatformCore.Plane.getPlaneById(planeId);
  }

  /**
   * Selects the current plane from the store as a lifecycle bind
   * @returns The plane.
   */
  usePlane(planeId: string): Plane | undefined {
    return PlatformCore.useSelector(state => PlatformCore.selectors.plane(state, planeId));
  }
}

export default PlatformModuleBase;
