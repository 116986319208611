import { forwardRef, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Link,
  Spacer,
  Stack,
} from '@mtb/ui';
import configStore from '../../../services/config';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';
import { StorageProjectMenuMoveFile } from './StorageProjectMenuMoveFile';

export const StorageProjectMenuFileLocation = () => {
  const [t] = useTranslation();
  const [isMoving, setIsMoving] = useState(false);
  const { project, parentFolderName } = useStorageProjectMenu();

  if (!parentFolderName || !project.parentFolderUrl) {
    return null;
  }

  return (
    // @ts-expect-error-next-line - Bad MtbUI types
    <FormControl fullWidth>
      <Stack
        alignItems="center"
        direction="row">
        {/* @ts-expect-error-next-line - Bad MtbUI types */}
        <FormLabel>{t('connection.location')}</FormLabel>
        {/* @ts-expect-error-next-line - Bad MtbUI types */}
        <Spacer />
        {configStore.config.feature_flag_user_collaboration && <StorageProjectMenuMoveFile setIsMoving={setIsMoving} />}
      </Stack>
      {/* @ts-expect-error-next-line - Bad MtbUI types */}
      <Input
        inputComponent={
          forwardRef((_props, ref) => (
            <Link
              // @ts-expect-error-next-line - Bad MtbUI types
              ref={ref}
              disabled={isMoving}
              href={project.parentFolderUrl}
              rel="noopener noreferrer"
              sx={theme => ({
                boxSizing: 'content-box',
                height   : theme.typography.pxToRem(24),
                padding  : `${theme.spacing(1)} ${parseInt(theme.spacing(2)) - 4}px`,
              })}
              target="_blank"
              underline="none">
              {parentFolderName}
            </Link>
          ))}
        sx={{ cursor: 'default' }} />
    </FormControl>
  );
};
