import { useMemo } from 'react';
import { useMenu } from '@mtb/ui';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import ProviderClient from '../../clients/provider';
import { STORAGE_PROVIDER_KEYS } from '../../constants';
import {
  useNewCloudStorageProject,
  useNewCloudStorageStore,
  useProvider,
} from '../../hooks';
import { getRootFolderName } from '../../utils';
import { StorageProjectMenuContext, initialState } from './context';

const queryClient = new QueryClient();

/**
 * @param {import('@').StorageProjectMenuContext & { children?: import('react').ReactNode }} props
 */
const StorageProjectMenuContextProvider = ({
  projectId,
  onFlush,
  onReopenProject,
  children,
}) => {
  const menuProps = useMenu({ id: 'storage-project' });
  const { defaultProvider } = useNewCloudStorageStore();
  const project = useNewCloudStorageProject(projectId);
  const oneDrive = useProvider(STORAGE_PROVIDER_KEYS.ONE_DRIVE);
  const googleDrive = useProvider(STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE);
  const hasAuthedProvider = useMemo(
    () => oneDrive.isSignedIn || googleDrive.isSignedIn,
    [googleDrive.isSignedIn, oneDrive.isSignedIn],
  );

  const { data: parentFolderName } = useQuery({
    queryKey: ['project-menu', 'parent-folder-name', project.id],
    queryFn : async () => {
      const item = await ProviderClient.getItem(project.connection);

      if (!item) {
        return null;
      }

      if (item.parentId === 'root') {
        return getRootFolderName(item.type);
      }

      const parent = await ProviderClient.getItem({
        driveId: item.driveId,
        itemId : item.parentId,
        type   : item.type,
      });

      if (!parent) {
        return null;
      }

      return parent.displayName;
    },
    enabled             : Boolean(project.parentFolderUrl && project.connection.type !== STORAGE_PROVIDER_KEYS.LOCAL),
    // The file location is not expected to change, so we can cache it indefinitely,
    // only refetch when the window regains focus, or if we manually flush the cache after we move the file
    staleTime           : Infinity,
    // Increase garbage collection time to 15 minutes since this data should be relatively static
    gcTime              : 15 * 60 * 1000,
    // Refecth on window focus to ensure the file location is up to date if they've moved it in the cloud
    refetchOnWindowFocus: 'always',
    // Disable retrying since we don't want to refetch if the query fails. Expected failures will be because the parent folder belongs to another person.
    retry               : false,
    retryOnMount        : false,
  });

  const values = useMemo(() => {
    return {
      ...initialState,
      parentFolderName,
      hasAuthedProvider,
      defaultProvider,
      oneDrive,
      googleDrive,
      project,
      menuProps,
      onFlush,
      onReopenProject,
    };
  }, [
    hasAuthedProvider,
    parentFolderName,
    defaultProvider,
    oneDrive,
    googleDrive,
    project,
    menuProps,
    onFlush,
    onReopenProject,
  ]);

  return (
    <StorageProjectMenuContext.Provider value={values}>
      {children}
    </StorageProjectMenuContext.Provider>
  );
};

export const StorageProjectMenu = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StorageProjectMenuContextProvider {...props} />
    </QueryClientProvider>
  );
};

export default StorageProjectMenu;
