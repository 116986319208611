import { GOOGLE_DRIVE, STORAGE_PROVIDER_KEYS } from '../../constants';
import { getItemTimestamps, getNameParts, isGoogleSheet } from '../../utils';
import { ProviderBase } from '../ProviderBase';

/**
 * Verifies if the given scope string matches the expected Google Drive scopes.
 * @param {string} scopeString - The scope string to be verified.
 * @returns {boolean} - True if the scope string is valid, otherwise false.
 */
export const verifyGdriveScope = (scopeString) =>
  GOOGLE_DRIVE.SCOPES.split(' ').every((s) =>
    scopeString.split(' ').some((newScope) => newScope === s),
  );

const googleEndpoint = 'https://www.googleapis.com/oauth2/v1';
const gDriveEndpointv3 = 'https://www.googleapis.com/drive/v3';
const gDriveEndpointv2 = 'https://www.googleapis.com/drive/v2';

const dirTransform = ({ files }, includeFolders = true, atRoot = false) => {
  return files.map((item) => itemTransform(item, includeFolders, atRoot));
};

/**
 * @returns {import('@').StorageProviderItem}
 */
const itemTransform = (item, includeFolders = true, atRoot = false) => {
  // item properties may be different depending on the api call (dirTransform or getItemById), account for either possibility
  const properties = item.properties?.length
    ? item.properties.reduce(
      (acc, val) => ({ [val.key]: val.value, ...acc }),
      {},
    )
    : item.properties;
  const checkoutUser = Boolean(properties?.checkoutUserName)
    ? {
      displayName: properties.checkoutUserName,
      email      : properties.checkoutUserEmail,
      photo      : properties.pid,
    }
    : undefined;

  let name = item.name || item.title;
  const driveId = '';

  // depending on whether v2 or v3 endpoint are called, item.parents may hold parent objects or just parent ids.
  const originalParentId = item.parents?.[0]?.id || item.parents?.[0];

  const parentId =
    atRoot || item.parents?.some((p) => p.isRoot) || !originalParentId
      ? 'root'
      : originalParentId;

  const parentReference = {
    id    : originalParentId,
    driveId,
    siteId: item.spaces?.[0] || 'drive',
  };
  // Handle google sheets - treat like Excel files
  if (isGoogleSheet(item)) {
    item.fileExtension = 'xlsx';
    name = `${name}.${item.fileExtension}`;
  }
  const folder =
    includeFolders && item.mimeType === 'application/vnd.google-apps.folder';
  const root = folder && !item.parents?.length;
  const nameParts = getNameParts(name);
  const timestamps = getItemTimestamps(item);

  return {
    type: STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE,
    ...nameParts,
    ...timestamps,
    id  : item.id,
    driveId,
    parentId,
    ...((item.ownedByMe || (Boolean(item.parents?.length) && !folder)) && {
      parentFolderUrl: `https://www.drive.google.com/drive/folders/${parentReference.id}`,
    }),
    size           : item.size,
    folder,
    createdDateTime: item.createdDateTime,
    createdBy      : { name: item?.owners?.[0]?.displayName },
    parentReference: {
      id     : parentReference.id,
      driveId: parentReference.driveId,
      siteId : parentReference.siteId,
    },
    mimeType : item.mimeType,
    extension: item.fileExtension,
    checkoutUser,
    root,
  };
};

export class GoogleDriveProviderAPI extends ProviderBase {
  /** @type {ProviderBase['getRootWebUrl']} */
  getRootWebUrl() {
    return 'https://www.drive.google.com';
  }

  /** @type {ProviderBase['_getRootInfo']} */
  async _getRootInfo() {
    const { id } = await this.authorizedApiCall({
      verb: 'GET',
      url : `${gDriveEndpointv3}/files/root`,
    });
    return `https://drive.google.com/drive/folders/${id}`;
  }

  /** @type {ProviderBase['setCache']} */
  setCache(id, account, auth) {
    if (!verifyGdriveScope(auth.scope)) {
      this.clearCache();
      return;
    }
    super.setCache(id, account, auth);
  }

  /** @type {ProviderBase['renameItem']} */
  renameItem(id, driveId, name) {
    return this.wrappedApiCall({
      url      : `${gDriveEndpointv3}/files/${id}`,
      verb     : 'PATCH',
      body     : { name },
      transform: (item) => item.name,
    });
  }

  /** @type {ProviderBase['getBreadcrumbs']} */
  async getBreadcrumbs(id, driveId) {
    let item = await this.getItemById(id, driveId);
    let done = item.parentId === 'root';
    const rootBreadcrumb = { id: 'root', webUrl: await this._getRootInfo() };
    const breadCrumbs = [];

    while (!done) {
      item = await this.getItemById(item.parentId, driveId);
      breadCrumbs.push({ ...item, webUrl: item.alternateLink });
      if (item.parentId === 'root' || item.error) {
        done = true;
        break;
      }
    }

    return [...breadCrumbs, rootBreadcrumb].reverse();
  }

  /** @type {ProviderBase['getFolderBreadcrumbs']} */
  async getFolderBreadcrumbs(id, driveId) {
    const rootBreadcrumb = { id: 'root', webUrl: await this._getRootInfo() };
    if (id === 'root') {
      return [rootBreadcrumb];
    }
    let item = await this.getItemById(id, driveId);
    const breadCrumbs = [{ ...item, webUrl: item.alternateLink }];
    while (item.parentId !== 'root') {
      item = await this.getItemById(item.parentId, driveId);
      breadCrumbs.push({ ...item, webUrl: item.alternateLink });
    }
    return [...breadCrumbs, rootBreadcrumb].reverse();
  }

  /** @type {ProviderBase['moveItem']} */
  async moveItem(id, _driveId, parentId) {
    const item = await this.getItemById(id);
    return this.authorizedApiCall({
      verb: 'PATCH',
      body: {},
      url : `${gDriveEndpointv3}/files/${id}?${new URLSearchParams({
        fields    : 'id,parents',
        addParents: parentId,
        ...(item.parents?.length
          ? { removeParents: item.parents?.map(({ id }) => id).join(',') }
          : {}),
      }).toString()}`,
      transform: itemTransform,
    });
  }

  /** @type {ProviderBase['createDefaultItem']} */
  createDefaultItem(name) {
    return this.wrappedApiCall({
      url : `${gDriveEndpointv3}/files`,
      verb: 'POST',
      body: {
        name,
        originalFilename: name,
        parents         : [
          this.getAutoSaveFolder().id === 'root'
            ? null
            : this.getAutoSaveFolder().id,
        ].filter(Boolean),
      },
    });
  }

  /** @type {ProviderBase['createInFolder']} */
  createInFolder(name, folder) {
    return this.wrappedApiCall({
      url : `${gDriveEndpointv3}/files`,
      verb: 'POST',
      body: {
        name,
        originalFilename: name,
        parents         : [folder.id === 'root' ? null : folder.id].filter(Boolean),
      },
    });
  }

  /** @type {ProviderBase['getAccount']} */
  getAccount(skipAuthRetry = false, auth = undefined) {
    return this.authorizedApiCall(
      {
        skipAuthRetry,
        url      : `${googleEndpoint}/userinfo`,
        transform: ({ name, email, id, picture }) => ({
          name,
          email,
          id,
          picture,
        }),
      },
      auth,
    );
  }

  /** @type {ProviderBase['getAuthedAccount']} */
  getAuthedAccount() {
    return this.wrappedApiCall({
      url      : `${googleEndpoint}/userinfo`,
      transform: ({ name, email, id, picture }) => ({
        name,
        email,
        id,
        picture,
      }),
    });
  }

  /** @type {ProviderBase['getAbout']} */
  getAbout(field = '*') {
    return this.wrappedApiCall({
      url      : `${gDriveEndpointv3}/about?fields=${field}`,
      transform: (fields) => (field === '*' ? fields : fields[field]),
    });
  }

  /** @type {ProviderBase['getRootChildren']} */
  getRootChildren(signal) {
    return this.wrappedApiCall({
      url      : `${gDriveEndpointv3}/files?orderBy=folder&q='root' in parents and trashed=false &fields=*`,
      transform: (files) => dirTransform(files, true, true),
      signal,
    });
  }

  /** @type {ProviderBase['getRecent']} */
  getRecent(_, signal) {
    return this.wrappedApiCall({
      url      : `${gDriveEndpointv3}/files?orderBy=modifiedTime desc&q=trashed=false &fields=*`,
      transform: (files) => dirTransform(files, false),
      signal,
    });
  }

  /** @type {ProviderBase['getNewRecent']} */
  getNewRecent(_, signal) {
    return this.wrappedApiCall({
      // Uses "viewedByMeTime <= now" to ensure that the file was viewed by the user at some point
      // since we can't query viewedByMe=true and viewedByMeTime is not always defined
      url      : `${gDriveEndpointv3}/files?orderBy=viewedByMeTime desc&q=trashed=false and viewedByMeTime <= '${new Date().toISOString()}' &fields=*`,
      transform: (files) => dirTransform(files, false),
      signal,
    });
  }

  /** @type {ProviderBase['getShared']} */
  getShared(signal) {
    return this.wrappedApiCall({
      url      : `${gDriveEndpointv3}/files?q=sharedWithMe=true and trashed=false &fields=*`,
      transform: dirTransform,
      signal,
    });
  }

  /** @type {ProviderBase['getFolderChildren']} */
  getFolderChildren(folderId, _driveId, signal) {
    return this.wrappedApiCall({
      url      : `${gDriveEndpointv3}/files?orderBy=folder&q='${folderId}' in parents and trashed=false &fields=*`,
      transform: dirTransform,
      signal,
    });
  }

  /** @type {ProviderBase['getItemById']} */
  getItemById(id, _driveId, useCache = true) {
    return this.wrappedApiCall({
      url      : `${gDriveEndpointv2}/files/${id}?fields=properties,*`,
      transform: itemTransform,
      cacheId  : useCache && id,
    });
  }

  /** @type {ProviderBase['searchItem']} */
  searchItem(text, pageSize = 25) {
    let nameQuery = `name contains '${text}'`;
    if (Array.isArray(text)) {
      nameQuery = `name contains '${text.join('\' or name contains \'')}'`;
    }
    return this.wrappedApiCall({
      url: `${gDriveEndpointv3}/files?q=${encodeURIComponent(
        `${nameQuery} and trashed=false`,
      )}&pageSize=${pageSize}&fields=*`,
      transform: dirTransform,
    });
  }

  /** @type {ProviderBase['getUser']} */
  getUser(principalName, params = '') {
    return this.wrappedApiCall({
      url: `${gDriveEndpointv3}/users/${principalName}?${params}`,
    });
  }

  /** @type {ProviderBase['getCheckoutUser']} */
  getCheckoutUser(item) {
    return item.checkoutUser;
  }

  /** @type {ProviderBase['duplicateItem']} */
  duplicateItem(item, name) {
    const { displayName, extension } = getNameParts(item.name);
    const itemName =
      name ??
      `${displayName} (1)${!isGoogleSheet(item) ? `.${extension}` : ''}`;

    return this.wrappedApiCall({
      url : `${gDriveEndpointv3}/files/${item.id}/copy?fields=properties,*`,
      verb: 'POST',
      body: {
        name         : itemName,
        parents      : item.parents,
        appProperties: item.appProperties,
      },
      transform: itemTransform,
    });
  }

  /** @type {ProviderBase['deleteItem']} */
  deleteItem(item) {
    return this.wrappedApiCall({
      url : `${gDriveEndpointv3}/files/${item.id}`,
      verb: 'DELETE',
    });
  }

  /** @type {ProviderBase['checkInItem']} */
  checkInItem(item) {
    return this.wrappedApiCall({
      url : `${gDriveEndpointv3}/files/${item.id}`,
      verb: 'PATCH',
      body: {
        properties: {
          checkoutUserId   : '',
          checkoutUserName : '',
          checkoutUserEmail: '',
          pid              : '',
        },
      },
    });
  }

  /** @type {ProviderBase['getRemainingStorageSpace']} */
  async getRemainingStorageSpace() {
    const storage = await this.getAbout();
    return storage.storageQuota?.limit - storage.storageQuota?.usage;
  }

  /**
   * Downloads the file content from the given item.
   * @param {import('@').StorageProviderItem} item
   * @returns {Promise<Blob>}
   */
  async downloadItem(item) {
    return await this.wrappedApiCall({
      url : `${gDriveEndpointv3}/files/${item.id}?alt=media`,
      type: 'blob',
      verb: 'GET',
    });
  }
}
