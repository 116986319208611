import type { ModuleData, ModuleOpenOptions, OpenOptionsV2 } from './types';
import type { DataItem } from '../../../clients/data/types';
import type { ModuleConfig, ModuleConfigKey } from '../../../modules/types';
import type { NestedPlaneState, Plane } from '../../types';
import type { CloudStoragePassthroughItem } from '@mtb/cloud-storage/types';
import { CloudStorageError } from '@mtb/cloud-storage';
import { deepMerge } from '@mtb/utilities';
import BrowserHistoryClient from '../../../clients/browser-history';
import CloudStorageClient from '../../../clients/cloud-storage';
import DataClient from '../../../clients/data';
import DialogsClient from '../../../clients/dialogs';
import I18nClient from '../../../clients/i18n';
import ModuleClient from '../../../clients/module';
import config from '../../../config';
import platformStore from '../../store';
import { PLANE_VARIANTS } from '../../store/reducers/planes/constants';
import { PlaneError } from '../../store/reducers/planes/error';
import { verifyAddPassthroughToPlane } from './verify';

const previewModules = [ModuleClient.MSSO.key, ModuleClient.DASHBOARD.key];

export async function getModule(options: OpenOptionsV2): Promise<ModuleConfig | undefined> {
  if (!options.data) {
    throw new Error('No module found for the given options.');
  }

  const user = platformStore.selectors.user(platformStore.getState());

  if (options.useDefaultModule) {
    const defaultModule = ModuleClient.getDefaultModuleByExtension(options.data.extension, user);
    if (!defaultModule) {
      throw new Error('No default module or user does not have access');
    }
    return defaultModule;
  }

  const modules = ModuleClient.getModulesByExtension(options.data.extension, user);
  if (modules.length === 0) {
    const disabledModules = ModuleClient.getDisabledModulesByExtension(options.data.extension, user);
    if (disabledModules?.length) {
      // skip the await since this dialog is just informational
      DialogsClient.showAlertDialog({
        title   : 'unableToOpenFile',
        message : 'unlicensedFile',
        severity: 'warning',
      });

      return undefined;
    }

    throw new Error('No modules found for the given extension.');
  }

  if (modules.length === 1) {
    return modules[0];
  }

  const moduleResult = await DialogsClient.showOpenInDialog({ item: options.data });
  if (!moduleResult) {
    return undefined;
  }

  return ModuleClient[moduleResult.moduleKey];
}

export function getModuleOptions(module: ModuleConfig, options: OpenOptionsV2): ModuleOpenOptions {
  const moduleData = getModuleData(module, options.data);
  const showPreview = !options.skipDataPreview && shouldShowPreview(module.key, moduleData);
  const customName = options.name ? CloudStorageClient.getNameParts(options.name).displayName : null;

  const defaultOptions: ModuleOpenOptions = {
    name          : customName ?? moduleData.projectData.name,
    extension     : moduleData.projectData.extension,
    module        : showPreview ? ModuleClient.DATACENTER.key : module.key,
    enableOpenData: false,
    state         : deepMerge(module.storage?.defaultPlaneState || {}, options.state || {}),
    extra         : options.extra || {},
    variant       : showPreview ? PLANE_VARIANTS.TEMPORARY : PLANE_VARIANTS.PERSISTENT,
    noProject     : false,
    showPreview,
    targetPlane   : {
      module: module.key,
      id    : options.planeId,
    },
    ...moduleData,
  };

  return getModuleOverrides(defaultOptions, options);
}

function getModuleOverrides(defaultOptions: ModuleOpenOptions, options: OpenOptionsV2): ModuleOpenOptions {
  const moduleOptions: ModuleOpenOptions = defaultOptions;
  const { isProjectData, passthroughData, projectData, showPreview } = defaultOptions;
  const suppliedProjectData = isProjectData && Boolean(options.data);

  switch (defaultOptions.module) {
    case ModuleClient.MSSO.key:
      moduleOptions.state = {
        isNew: !suppliedProjectData,
      };
      break;
    case ModuleClient.DATACENTER.key:
      moduleOptions.name = options.name ?? passthroughData?.name ?? projectData.name;
      moduleOptions.enableOpenData = true;
      moduleOptions.noProject = true;
      moduleOptions.state = {
        isNew      : !Boolean(options.data),
        isPreview  : showPreview,
        targetPlane: defaultOptions.targetPlane,
      };
      break;
    case ModuleClient.DASHBOARD.key:
      moduleOptions.state = {
        isNew: !suppliedProjectData,
      };
      break;
    case ModuleClient.WSO.key:
      moduleOptions.verifyData = (data?: DataItem) => {
        if (['qcpx', 'qctx'].includes(data?.extension ?? '') && (data?.size ?? 0) > 419430400) {
          DialogsClient.showAlertDialog({
            title   : 'wsoCannotUploadFileTitle',
            message : 'wsoCannotUploadFileMessage',
            severity: 'error',
          });
          return [false, `The file (${data?.size}) exceeds the max size limit.`];
        }
        return [true];
      };

      if (Boolean(moduleOptions.passthroughData)) {
        moduleOptions.state.scenario = 'openingPassthrough';
        if (moduleOptions.passthroughData?.extension === 'qctx') {
          moduleOptions.name = moduleOptions.passthroughData.name;
        }
      } else {
        const module = ModuleClient.WSO;
        const defaultExtension = module.storage?.defaultExtension || '';
        if (moduleOptions.projectData.extension === 'qcpx') {
          moduleOptions.extension = defaultExtension.startsWith('.') ? defaultExtension.slice(1) : defaultExtension;
          moduleOptions.onBeforeCreate = async (project: DataItem) => {
            const originalName = project.name;
            await project.duplicate();
            await project.rename(`${originalName}${defaultExtension}`);
          };
        }
      }
      break;
    default:
      break;
  }

  return moduleOptions;
}

export function toPlane(plane: Plane): void {
  platformStore.actions.setCurrentLayout({
    currentModuleKey: plane.module,
    currentPlaneId  : plane.id,
  });

  BrowserHistoryClient.updateBrowserHistory(
    {
      currentModuleKey: plane.module,
      currentPlaneId  : plane.id,
    },
    { replace: false },
  );
}

export async function loadPlaneProject(plane: Plane, options: ModuleOpenOptions): Promise<void> {
  try {
    await options.onBeforeCreate?.(options.projectData);
    const project = await options.projectData.createProject(`${plane.name}${plane.extension}`);
    platformStore.actions.updatePlane(plane.id, { cloudStorageId: project.id });
  } catch (error) {
    let planeError: PlaneError;
    if (error instanceof CloudStorageError) {
      planeError = PlaneError.FromCloudStorageError(error);
    } else {
      const err = error instanceof Error ? error : new Error('Failed to create a project for the plane.');
      planeError = PlaneError.FromUnknownError(err);
    }
    platformStore.actions.setPlaneError(plane.id, planeError);
  }
}

export async function openPassthroughData(plane: Plane, options: ModuleOpenOptions): Promise<void> {
  if (!options.passthroughData) {
    return;
  }

  let passthroughItem = {} as CloudStoragePassthroughItem;
  if (!options.enableOpenData) {
    passthroughItem = await addPassthroughToPlane(plane, options.passthroughData);
  }

  const updatedPlane = platformStore.getState().planes[plane.id];
  await ModuleClient.Events.open(updatedPlane, {
    ...passthroughItem,
    data : options.passthroughData,
    extra: options.extra,
  });
}

function getModuleData(module: ModuleConfig, data?: DataItem): ModuleData {
  // TODO: hacked until we properly register DC project types in module config (if any)
  if (module.key === ModuleClient.DATACENTER.key) {
    return { isProjectData: false, projectData: getDefaultProjectData(module), passthroughData: data };
  }

  // if no data provided, opening a blank project
  const isProjectData = !data ? true : module.storage?.filter?.includes(`.${data.extension}`) ?? false;

  return {
    isProjectData,
    projectData    : data && isProjectData ? data : getDefaultProjectData(module), // always return project data incase we need to create a plane
    passthroughData: isProjectData ? undefined : data,
  };
}

function getDefaultProjectData(module: ModuleConfig): DataItem {
  const defaultName = I18nClient.t(module.storage?.defaultProjectName || 'untitled');
  const name = defaultName + module.storage?.defaultExtension;
  return DataClient.createEmptyItem(name);
}

function shouldShowPreview(moduleKey: ModuleConfigKey, data: ModuleData): boolean {
  if (!config.feature_flag_dc_preview || data.isProjectData || !previewModules.includes(moduleKey)) {
    return false;
  }

  // ensure DataCenter can open the data type
  return ModuleClient.DATACENTER.storage?.passthroughFilter?.includes(`.${data.passthroughData?.extension}`) ?? false;
}

/**
 * Adds the passthrough item to the given plane either by updating the plane's state,
 * or by opening the passthrough item if the plane is currently open.
 * @param plane - The plane to add the passthrough item to.
 * @param data - The data item to add to the plane.
 * @returns The plane with the passthrough item added.
 * @deprecated - This function is deprecated and will be removed once consumers only use event system.
 */
async function addPassthroughToPlane(plane: Plane, data: DataItem): Promise<CloudStoragePassthroughItem> {
  verifyAddPassthroughToPlane(plane, data);

  const passthroughItem = await data.createPassthrough();
  // TODO: Remove this once we go live with the V2 Work.
  // Until we go live we need a way to instruct MSSO when to use v2 functionality
  // in their onOpen event. This can be removed once V2 goes live and
  // we can remove this and th{e V1 on open code from MSSO onOpen handler.
  if (plane.module === ModuleClient.MSSO.key) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Ignore this temporary property.
    passthroughItem.onOpenV2 = true;
  }
  // If a plane isn't currently open or the current plane id is different the given plane id,
  // we need to add the passthrough item to the target plane's state. This will allow
  // the plane to load the passthrough item the next time the plane is opened.
  const currentPlane = platformStore.selectors.currentPlane(platformStore.getState());
  const isCurrentPlaneActive = currentPlane && currentPlane?.id === plane.id;
  if (!isCurrentPlaneActive) {
    const planeState = deepMerge(plane.state, {
      passthroughItem,
    }) as NestedPlaneState;
    platformStore.actions.updatePlane(plane.id, { state: planeState });
    return passthroughItem;
  }

  return passthroughItem;
}
